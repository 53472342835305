import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "../../gatsby-plugin-theme-ui/index";
import Metadata from "../metadata/metadata"
import Header from "../header/header"
import Footer from "../footer/footer"
import { Styled } from "theme-ui"

export default function Layout({ children }) {
    return ( 
        <ThemeProvider theme = { theme } >
            <Metadata/>
            <Header/> 
            <Styled.div>
                { children } 
            </Styled.div>
            <Footer />
        </ThemeProvider>
    );
}