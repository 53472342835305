import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

const meta = () => (
    <StaticQuery 
    query={graphql`
        query {
            allMarkdownRemark(
                filter: { frontmatter: { menu: { eq: "meta" } } }, 
                sort: {order: ASC, fields: frontmatter___weight}) 
            {
                edges {
                    node {
                        frontmatter {
                            title
                            navtitle
                        }
                        fields {
                            slug
                        }
                        id
                    }
                }
            }
        }
    `}
render={data => (
        <nav title='meta navigation'>
            <ul>
                { data.allMarkdownRemark.edges.map(({ node }) => ( 
                <li>
                    <Link to={node.fields.slug}>{node.frontmatter.navtitle || node.frontmatter.title}</Link>
                </li>
                )) 
                }
            </ul>
        </nav>
    )}
    />
);
export default meta;