/** @jsx jsx */ //Note From John: this has to be at the top of the page for sx to work properly
import { jsx } from "theme-ui";

export default function Footer({ children }) {
    const now = new Date();
    const year = now.getFullYear();
    
    return ( 
        <footer
            sx={{
                background: '#415462',
                fontFamily: 'body',
                minHeight: ['15px','15px','30px'],
                height: 'auto',
                mx: ['0px','30px','50px'],
                py: '30px',
                pt: ['15px', '15px', '20px'],
                pb: ['5px', '5px', '10px'],
                position: 'fixed',
                bottom: '0px',
                width: ['100%','90%'],
            }}
        > 
            <p
                sx={{
                    color: '#a9a305',
                    float: ['none','none','left'],
                    pl: ['none','none','100px'],
                    textAlign:['center','center','left'],
                    pr: ['none','none','200px'],
                    pb:['.5rem','.5rem','none']
                }}
            >
                IDEAS THAT DRIVE PERFORMANCE
            </p>
            <p
                sx={{
                    fontSize: '12px',
                    //m:'0',
                    p:'0',
                    pr: ['none','none','50px'],
                    textAlign:['center','center','right'],
                    margin:'0.5rem 0',
                }}
            >
                <a href="/contact" sx={{display:'inline-block',color:'#699ec9',margin:'0 0 0 1rem'}}>Contact Us</a>
                <a href="/privacy" rel="privacy-policy" sx={{display:'inline-block',color:'#699ec9',margin:'0 0 0 1rem'}}>Privacy Policy</a>
                <a href="/terms" rel="terms-of-service" sx={{display:'inline-block',color:'#699ec9',margin:'0 0 0 1rem'}}>Terms of Use</a>
            </p>
            <p
                sx={{
                    color: '#ffffff',
                    fontSize: '12px',
                    //m:'0',
                    p:'0',
                    pr: ['none','none','50px'],
                    textAlign:['center','center','right'],
                    margin:'0.5rem 0',
                }}
            >
                &copy;Copyright {year}, Brandlogic Corp. All rights reserved. <br />
                Personal information is collected per our <a href="/privacy" rel="privacy-policy" sx={{color:'#699ec9'}}>Privacy Policy</a>
            </p>
        </footer>
    );
}
