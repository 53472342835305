/** @jsx jsx */ //Note From John: this has to be at the top of the page for sx to work properly
import { jsx } from "theme-ui";
import { StaticQuery, graphql, Link } from 'gatsby';

const nav = () => (
    <StaticQuery 
    query={graphql`
        query {
            allMarkdownRemark(
                filter: { frontmatter: { menu: { eq: "primary" } } }, 
                sort: {order: ASC, fields: frontmatter___weight}) 
            {
                edges {
                    node {
                        frontmatter {
                            title
                            navtitle
                        }
                        fields {
                            slug
                        }
                        id
                    }
                }
            }
        }
    `}        
    render={data => (
            <nav
                sx={{
                    borderBottom: 'solid 1px #a9a305',
                    ml: ['20px', '20px','50px'],
                    pb: '15px',
                    textAlign: 'right',
                    width: '90%',
                    }}
            >
                <ul 
                   sx={{
                    listStyleType: 'none',
                    m: 0,
                    p: 0,
                    mr: '10px',
                    }}
                >
                 { data.allMarkdownRemark.edges.map(({ node }) => ( 
                    <li 
                        key={node.id}
                        sx={{
                        display:'inline',
                        pl: '30px',
                        }}
                    >
                        <Link to={node.fields.slug}
                            sx={{
                                color: '#415462',
                                fontFamily: 'body',
                                fontWeight: '600',
                                textDecoration: 'none',
                                }}
                        >
                            {node.frontmatter.navtitle || node.frontmatter.title}
                        </Link>
                    </li>
                    )) 
                 }
                </ul>
            </nav>
        )}
    />
);
export default nav;